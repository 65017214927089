import React, { useEffect } from "react";
import { IInterestLocationProps } from "./InterestLocationProps";
import SEO from "../../components/global/SEO/SEO";
import { graphql, PageProps } from "gatsby";
import Layout from "../../components/global/Layout/Layout";
import { getParagraph } from "../../utils/paragraphHelpers";
import ParagraphLayout from "../../components/common/ParagraphLayout/ParagraphLayout";
import { IGenericParagraph, IGenericParagraphNode } from "../../@types/IParagraph";
import { Constants } from "../../@types/Constants";
import { HotelListing } from "./InterestLocationHelpers";
import { Container, Row } from "react-bootstrap";
import FeaturesTabsCity from "../../components/common/FeaturesTabsCity/FeaturesTabsCity";
import Title2 from "../../components/global/Title2/Title2";
import { SubTitle2 } from "../CityPage/CityPageHelpers";
import { addPageTypeInGTMDataLayer } from "../../utils/datalayers";
import { useAppDispatch } from "../../hooks";
import { setSearch } from "../../redux/slices/Search/search";
import BreadCrumb from "../../components/common/Breadcrumb/Breadcrumb";
import { useWebFramed } from "../../hooks/useWebFramed";
import { extractBrandFromUrl } from "../../utils/helpers";

const InterestLocation = (props: PageProps<IInterestLocationProps, null, null>) => {
    const dispatch = useAppDispatch();
    const isWebFramed = useWebFramed();
    const node = {...props.data.interestLocationsContent};
    const seoTitle = node.field_intlocation_snt_page_title;
    const seoDesc = node.field_intlocation_snt_meta_desc;
    const hotelSearchFilters = props.data.allRlhsite.edges.length ? props.data.allRlhsite.edges[0].node.field_filter_selected : [];
    const brandsArr = extractBrandFromUrl(props.location.search);
    // Location Reference Info
    const locationInfo = node.relationships.field_locations_reference;
    const coordinates = locationInfo.field_coordinates;
    const locationName = locationInfo.name;
    const lat = locationInfo.field_coordinates.lat;
    const lon = locationInfo.field_coordinates.lon;
    const countryCode = locationInfo.field_address?.country_code;

    //Interest Reference Info
    const interest = node.relationships.field_interest_reference;
    const interestURL = interest.path.alias.slice(1); // removing '/' from url path
    const interestName = interest.name;
    // Filter Feature Based on SiteID
    const filterSonestaParagraphs = (paragraph: IGenericParagraphNode) => {
        return paragraph?.relationships?.field_sites ? (paragraph?.relationships.field_sites.machine_name === "sonesta" ? true : false ): true;
    };
    const siteParagraphs = node?.relationships.field_sections?.filter(filterSonestaParagraphs);
    const siteBelowSearchParagraphs = node?.relationships.paragraphsBelowSearch?.filter(filterSonestaParagraphs);
    const paragraphs: Array<IGenericParagraph> = siteParagraphs ? siteParagraphs.map(getParagraph) : [];
    const belowSearchParagraphs = siteBelowSearchParagraphs ? siteBelowSearchParagraphs.map(getParagraph) : [];
    const pageBanner = paragraphs.filter((p:IGenericParagraph) => { return p ? p.props.paragraphtype === Constants.PARAGRAPH_PAGE_BANNER : false; });
    const pageIntro = paragraphs.filter((p:IGenericParagraph) => { return p ? p.props.paragraphtype === Constants.PARAGRAPH_PAGE_INTRO : false; });
    // Filter Feature Based on SiteID
    const sonestaFeatures = node.relationships.field_features.filter(feature =>
        (feature.relationships?.field_sites ? (feature.relationships.field_sites.machine_name === "sonesta" ? true : false) : true));

    useEffect(() => {
      if (coordinates !== null) {
        const payload = {
          latitude: lat,
          longitude: lon,
          searchString: locationName,
          searchType: "interest",
          distance: 50
        };

        dispatch(setSearch(payload));
        // setShouldRedirect(false);
      }
      addPageTypeInGTMDataLayer("search");
    }, []);

    return (
      <Layout showMerchandisingBlock={countryCode == "US"}>
        <SEO
          title={seoTitle}
          description={seoDesc}
          koddiTitle="Interest-Location"
          includeLeaflet
        />
        {pageBanner}
        <ParagraphLayout {...paragraphs} />
        <HotelListing
          lat={lat}
          lon={lon}
          interestURL={interestURL}
          location={props.location}
          hotelSearchFilters={hotelSearchFilters}
          interestName={interestName}
          locationReferenceName={locationName}
          isPageIntroAvailable={pageIntro.length > 0 ? true : false}
          brandsInUrl={brandsArr}
        />
        <ParagraphLayout {...belowSearchParagraphs} />
        {sonestaFeatures !== null && sonestaFeatures.length ? (
          <Container
            className="bg-light pt-4 pb-4 pt-lg-5 pb-lg-5 mb-4 mb-lg-5 position-relative"
            fluid
          >
            <a
              className="anchor-target visually-hidden"
              id="HotelDetails"
              aria-hidden={true}
            />
            <Container fluid="sm" className="p-0">
              <Row>
                <SubTitle2>{"About The Interest"}</SubTitle2>
                <Title2>{"Attractions"}</Title2>
              </Row>

              <FeaturesTabsCity features={sonestaFeatures} />
            </Container>
          </Container>
        ) : null}
        {!isWebFramed && (
          <div
            className={"bg-light pt-4 pb-4 pt-lg-5 pb-lg-5 position-relative"}
          >
            <div className={"container-sm"}>
              <BreadCrumb
                location={props.location}
                activeTitle={interestName}
                countryCode={countryCode}
                cityPath={props?.data?.locationCity}
                statePath={props?.data?.locationState}
              />
            </div>
          </div>
        )}
      </Layout>
    );
};

export default InterestLocation;

export const query = graphql`
query($slug: String!, $statePath: String, $cityPath: String) {
    interestLocationsContent (path: {alias: { eq: $slug}}) {
        name
        field_intlocation_snt_meta_desc
        field_intlocation_snt_page_title
        path {
          alias
        }
        relationships {
          field_locations_reference {
            ... on location__city {
              name
              field_coordinates {
                lat
                lon
              }
              field_address {
                country_code
              }
            }
            ... on location__airport {
              name
              field_coordinates {
                lat
                lon
              }
              field_address {
                country_code
              }
            }
            ... on location__point_of_interest {
              name
              field_coordinates {
                lat
                lon
              }
              field_address {
                country_code
              }
            }
          }
          field_interest_reference {
            path {
              alias
            }
            name
          }
          field_sections {
              type: __typename
              ...ParagraphPageBanner
              ...ParagraphPageIntro
              ...ParagraphMediaCta
              ...ParagraphPageTeaser
              ...ParagraphTeaserList
              ...ParagraphBody
              ...ParagraphFeaturedProperties
              ...ParagraphFrequentlyAskedQuestions
          }
          paragraphsBelowSearch: field_sections_below_search {
            type: __typename
            ...ParagraphPageIntro
            ...ParagraphMediaCta
            ...ParagraphPageTeaser
            ...ParagraphTeaserList
            ...ParagraphBody
            ...ParagraphFeaturedProperties
            ...ParagraphFrequentlyAskedQuestions
          }
          field_features{
              field_feature_tab_title
              field_feature_title
              field_feature_description{
                value
              }
              field_feature_image {
                alt
              }
              relationships {
                field_sites {
                  machine_name
                }
                field_feature_image {
                  url
                }
                field_sub_feature {
                  field_feature_description {
                    value
                  }
                  field_feature_tab_title
                  field_feature_title
                  field_feature_image {
                    alt
                  }
                  relationships {
                    field_feature_image {
                      id
                      url
                    }
                  }
                }
              }
            }
          }
    }
    allRlhsite(filter: {machine_name: {eq:"sonesta"}}) {
      edges {
        node {
          field_filter_selected
        }
      }
    }
    locationState: nodeState(path: {alias: {eq: $statePath}}) {
    path {
      alias
    }
    }
    locationCity: locationCity(path: {alias: {eq: $cityPath}}) {
      path {
        alias
      }
    }
}
`;