import * as React from "react";
import { interestTitle } from "./InterestLocation.module.scss";
import { Col, Container, Row } from "react-bootstrap";
import SubTitle from "../../components/global/SubTitle/SubTitle";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { setSearch, searchSelector } from "../../redux/slices/Search/search";
import SearchResult from "../../components/common/Search/SearchResult/SearchResult";

export const HotelListing = (props: any) => {
  const dispatch = useAppDispatch();
  const [searchDistance, setSearchDistance] = React.useState(50);
  const search = useAppSelector(searchSelector);
  const title = props.interestName + " Hotels Near " + props.locationReferenceName;
  
  React.useEffect(() => {
    setSearchDistance(50);
    dispatch(setSearch({...search, 'distance': 50}));
  }, []);

  React.useEffect(() => {
    setSearchDistance(0); // so that distance from filter can take precendence
  }, [search]);

      return(<Container fluid className="bg-light pt-4 pb-4">
        <Container>
          <Row className="justify-content-md-center">
            <Col md={10} className="text-center">
              <SubTitle>Nearby Hotels</SubTitle>
                {props.isPageIntroAvailable ?<Title2>{title}</Title2> : <Title>{title}</Title> }
              
            </Col>
          </Row>
        </Container>
        <SearchResult
          background="white"
          distance={searchDistance}
          sortBy={props.sortBy}
          location={props.location}
          hotelSearchFilters ={props.hotelSearchFilters}
          interestSearch = {true}
          interestURL={props.interestURL}
          brandsInUrl={props.brandsInUrl}
      />
      </Container>);
  };


const Title = (props: any) => {
    return (<h1 className={interestTitle}>{props.children}</h1>);
  };

const Title2 = (props: any) => {
  return (<h2 className={interestTitle}>{props.children}</h2>);
  };
